import React, { useCallback, useEffect, useState } from "react";
// import landingPage from './landing-page.html'
import Footer from "../../components/footer";
import LandingPageFilmEntry from "../landing-page-film-entry";
import axios from "axios";
import { BACKEND_URL } from "../../constants/Strings";

function LandingPage() {
  useEffect(() => {
    let slideArray = document.getElementsByClassName("tabs-menu")[0].childNodes;
    let tempArray = [];
    for (let i = 0; i < slideArray.length; i++) {
      if (slideArray[i].classList != null) {
        tempArray.push(slideArray[i]);
      }
    }
    slideArray = tempArray;
    //returns current slide index
    function getCurr() {
      if (slideArray[0].classList.contains("w--current")) {
        return 0;
      }
      if (slideArray[1].classList.contains("w--current")) {
        return 1;
      } else {
        return 2;
      }
    }

    let signInNavbar = document.getElementById("sign-in-navbar-button");
    let signUpNavbar = document.getElementById("sign-up-navbar-button");
    let navbarImage = document.getElementById("festiv-logo-navbar");

    function makeNavbarWhite() {
      signInNavbar.style.backgroundColor = "rgba(0,0,0,0)";
      signInNavbar.style.color = "white";
      signUpNavbar.style.color = "white";
      //Also Need To Swap Out Logo With White Logo
      navbarImage.srcset =
        "images/white-festiv-logo.png 500w, images/white-festiv-logo.png 800w, images/white-festiv-logo.png 1080w, images/white-festiv-logo.png 1280w";
    }
    function makeNavbarBlue() {
      signInNavbar.style.backgroundColor = "rgba(0,0,0,0)";
      signInNavbar.style.color = "#333e51";
      signUpNavbar.style.color = "#fff";
      //Swap White Logo For Original Blue Logo
      navbarImage.srcset =
        "images/blue-festiv-logo-with-text.png 500w, images/blue-festiv-logo-with-text.png 800w, images/blue-festiv-logo-with-text.png 1080w, images/blue-festiv-logo-with-text.png 1280w";
      console.log(navbarImage);
    }

    // window.addEventListener("scroll", function () {
    //   let navbar = document.getElementById("navbar-home-transparent");
    //   // Set starting position
    //   if (window.pageYOffset >= 100) {
    //     navbar.style.backgroundColor = "rgba(255,255,255,1)" ;
    //     makeNavbarBlue();
    //   } else {
    //     navbar.style.backgroundColor = "rgba(255,255,255,0)";
    //     if (getCurr() !== 1) {
    //       makeNavbarWhite();
    //     } else {
    //       makeNavbarBlue();
    //     }
    //   }
    // });
    // slideArray[0].addEventListener("click", () => {
    //   if (window.scrollY < 100) {
    //     setTimeout(makeNavbarWhite, 100);
    //   }
    // });
    // slideArray[1].addEventListener("click", () => {
    //   if (window.scrollY < 100) {
    //     setTimeout(makeNavbarBlue, 100);
    //   }
    // });
    // slideArray[2].addEventListener("click", () => {
    //   if (window.scrollY < 100) {
    //     setTimeout(makeNavbarWhite, 100);
    //   }
    // });
    // makeNavbarWhite();
    makeNavbarBlue();
  });

  const [films, setFilms] = useState();
  useEffect(() => {
    const getFromLaravel = async (api) => {
      const response = await axios.get(`${BACKEND_URL}${api}`);
      // console.log(response.data.films);
      setFilms(response.data.featuredFilms);
    };
    getFromLaravel("api/get-featured");
  }, []);

  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => {
        // If the current tab is the last tab, switch to the first tab, else switch to the next tab
        return prevTab === 3 ? 1 : prevTab + 1;
      });
    }, 10000); // Change the interval time (in milliseconds) as needed

    return () => clearInterval(interval); // Cleanup function to clear interval on component unmount
  }, []);

  return (
    <div className="body">
      <div
        data-collapse="medium"
        data-animation="default"
        data-duration="400"
        id="navbar-home-transparent"
        role="banner"
        class="navbar-custom navbar-home w-nav"
      >
        <a
          href="/index.html"
          aria-current="page"
          class="brand brand-3 w-nav-brand w--current"
        >
          <img
            src="/images/white-festiv-logo.png"
            width="114"
            id="festiv-logo-navbar"
            sizes="(max-width: 479px) 80px, (max-width: 767px) 100px, 114px"
            srcset="/images/white-festiv-logo.png 500w, /images/white-festiv-logo.png 800w, /images/white-festiv-logo.png 1080w, /images/white-festiv-logo.png 1280w"
            alt="white-festiv-logo"
            class="image-32"
          />
        </a>
        <nav role="navigation" className="nav-menu-2 w-nav-menu">
          <a href="/sign-up.html" className="button mobile-sign-in w-button">
            START FREE TODAY
          </a>
          <a href="/sign-in.html" className="button mobile-sign-in w-button">
            LOG IN
          </a>
          <a
            href="/sign-up.html"
            id="sign-up-navbar-button"
            className="button-1 action-button-white action-button-blue _1234 w-button sign-up-btn"
          >
            START FREE TODAY
          </a>
          <a
            href="/sign-in.html"
            id="sign-in-navbar-button"
            class="button my-account-button w-button"
          >
            LOG IN
          </a>
        </nav>
        <div class="script-for-popup-navbar w-embed w-script"></div>
        <div class="menu-button w-nav-button">
          <div class="icon-5 w-icon-nav-menu"></div>
        </div>
      </div>

      <section className="section-5" id="slideshow">
        <div
          data-duration-in="1000"
          data-easing="ease-in-out-quad"
          data-duration-out="500"
          className="w-tabs"
        >
          <div className="tabs-content w-tab-content">
            <div
              data-w-tab="Tab 1"
              className={`tab-pane-tab-1 w-clearfix w-tab-pane ${
                activeTab === 1 && "w--tab-active"
              }`}
            >
              <div className="div-block-65">
                <img
                  src="/images/new_filmmakers.png"
                  loading="lazy"
                  // sizes="(max-width: 1919px) 100vw, 1500.76171875px"
                  // srcSet="/images/new_filmmakers-p-500.png 500w, /images/new_filmmakers-p-800.png 800w, /images/new_filmmakers-p-1080.png 1080w, /images/new_filmmakers-p-1600.png 1600w, /images/new_filmmakers.png 1721w"
                  alt=""
                  className="image-45"
                />
                <div className="w-layout-grid grid-24">
                  <img
                    src="/images/filmmaker-background-mobile-two.png"
                    loading="lazy"
                    alt=""
                    className="image-48"
                  />
                </div>
              </div>
              <div className="w-layout-grid-1 grid">
                <div id="w-node-df168eede505-aa9849fb" className="div-block-4">
                  <img
                    src="/images/the-filmmakers-marketplace-large.png"
                    loading="lazy"
                    sizes="(max-width: 479px) 84vw, 100vw"
                    srcSet="/images/the-filmmakers-marketplace-large.png 500w, /images/the-filmmakers-marketplace-large.png 800w, /images/the-filmmakers-marketplace-large.png 1216w"
                    alt=""
                    className="image-44"
                  />
                  <div class="plan-features-text">
                    <h1 className="text-white w-40">
                      Screen Your Films for Top Global Distributors!
                    </h1>
                    <h3>Get Contacted Directly from Distributors!</h3>
                    <h3>Get Exclusive Contact to Award-Winning Filmmakers!</h3>
                    <h3>New Films Announced and Awarded Monthly!</h3>{" "}
                    <h3>Screening Starts July 28th!</h3>{" "}
                    {/* <strong>Enter The Pocket Film Festival!</strong>{" "}
                    <a
                      href="/https://forms.gle/X5M5nArP3qq9p3eC6"
                      class="small-light-link"
                    >
                      (Submit Here)
                    </a> */}
                    {/* <strong className="plan-features-text-lg">
                      <h2>
                        Get ready to be discovered, Submit Your Film Today!
                      </h2>
                    </strong> */}
                  </div>
                  <br />
                  {/* Jerry Changes  */}
                  {/* <div className=" homepage-submit"> */}

                  {/* <h2 className="text-block-2">Submit Today!</h2> */}
                  <a
                    id="homepage-conversion-button"
                    href="/film-submission-form.html"
                  >
                    <button className="button-2 w-button">SUBMIT FILM</button>
                  </a>
                  {/* </div> */}
                </div>
              </div>
            </div>
            <div
              data-w-tab="Tab 2"
              className={`tab-pane-tab-2 w-tab-pane ${
                activeTab === 2 && "w--tab-active"
              }`}
            >
              <div className="div-block-66">
                <img
                  src="/images/new_distributors.png"
                  loading="lazy"
                  sizes="(max-width: 991px) 100vw, (max-width: 1439px) 1113.92578125px, 1494.08203125px"
                  srcSet="/images/new_distributors-p-500.png 500w, /images/new_distributors-p-800.png 800w, /images/new_distributors-p-1080.png 1080w, /images/new_distributors-p-1600.png 1600w, /images/new_distributors.png 1899w"
                  alt=""
                  className="image-46"
                />
                <div className="w-layout-grid grid-22">
                  <img
                    src="/images/distributor-background-mobile.png"
                    loading="lazy"
                    width="412"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 411.9921875px, 100vw"
                    srcSet="/images/distributor-background-mobile-p-500.png 500w, /images/distributor-background-mobile.png 526w"
                    alt=""
                    className="image-49"
                  />
                </div>
              </div>
              <div className="w-layout-grid grid">
                <div id="w-node-86a765efe3ee-aa9849fb" className="div-block-4">
                  <div class="plan-features-text-dark">
                    <img
                      src="/images/the-film-buyers-marketplace.png"
                      loading="lazy"
                      width="504"
                      sizes="(max-width: 479px) 70vw, (max-width: 767px) 332.44140625px, (max-width: 991px) 49vw, 490.21484375px"
                      srcSet="/images/the-film-buyers-marketplace-p-500.png 500w, /images/the-film-buyers-marketplace.png 748w"
                      alt=""
                      className="image-43"
                    />
                    <h1 className="w-40">
                      Screen Films You Missed at Festivals!
                    </h1>

                    <h3>Contact Filmmakers Directly!</h3>

                    <h3>Exclusive Access Window To Screen!</h3>

                    <h3>Acquire Award Winning Films!</h3>

                    <h3>Discover the best new films!</h3>
                    <h3>Screening Starts July 28th!</h3>
                  </div>

                  {/* <h1 className="heading-7">Discover the best new films!</h1> */}
                  {/* <a
                    id="homepage-conversion-button"
                    href="/film-submission-form.html"
                  >
                    <button className="button-2 w-button">SUBMIT FILM</button>
                  </a> */}
                </div>
              </div>
            </div>
            <div
              data-w-tab="Tab 3"
              className={`tab-pane-tab-3 w-tab-pane ${
                activeTab === 3 && "w--tab-active"
              }`}
            >
              <div className="div-block-67">
                <img
                  src="/images/new_audeiences.png"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 615.625px, (max-width: 991px) 89vw, (max-width: 1439px) 881.46484375px, 1182.28515625px"
                  srcSet="/images/new_audeiences-p-500.png 500w, /images/new_audeiences-p-800.png 800w, /images/new_audeiences-p-1080.png 1080w, /images/new_audeiences.png 1325w"
                  alt=""
                  className="image-47"
                />
                <div className="w-layout-grid grid-23">
                  <img
                    src="/images/audience-mobile-vire.png"
                    loading="lazy"
                    width="554"
                    sizes="100vw"
                    srcSet="/images/audience-mobile-vire-p-500.png 500w, /images/audience-mobile-vire.png 661w"
                    alt=""
                    className="image-50"
                  />
                </div>
              </div>
              <div className="w-layout-grid grid">
                <div id="w-node-804838ccba26-aa9849fb" className="div-block-3">
                  <h1 className="text-white w-40">
                    Stream the newest films, updated daily!
                  </h1>

                  <div class="plan-features-text">
                    <h3>Stream from Any Device Anytime!</h3>

                    <h3>Watch Award Winning Films!</h3>

                    <h3>Discover Great New Filmmakers!</h3>

                    <h3>View Films, Get Points, Win Prizes!</h3>

                    <strong className="plan-features-text-lg">
                      <h2>Screenings Start July 28th!</h2>
                    </strong>
                  </div>

                  {/* <div>
                    <a
                      id="homepage-conversion-button"
                      href="/film-submission-form.html"
                    >
                      <button className="button-2 w-button">SUBMIT FILM</button>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="tabs-menu w-tab-menu">
            <a
              data-w-tab="Tab 1"
              className={`three-different-tabs filmmakers-tab w-inline-block w-tab-link ${
                activeTab === 1 && "w--current"
              }`}
              href="/##"
            >
              <h1 className="text-block-5">FILMMAKERS</h1>
            </a>
            <a
              data-w-tab="Tab 2"
              className={`three-different-tabs distributors-tab w-inline-block w-tab-link ${
                activeTab === 2 && "w--current"
              }`}
              href="/##"
            >
              <h1 className="text-block-22">DISTRIBUTORS</h1>
            </a>
            <a
              data-w-tab="Tab 3"
              className={`three-different-tabs audiences-tab w-inline-block w-tab-link ${
                activeTab === 3 && "w--current"
              }`}
              href="/##"
            >
              <h1 className="text-block-23">AUDIENCE</h1>
            </a>
          </div>
        </div>
      </section>
      <article className="homepage-article">
        <h1 className="featured-films-title-homepage">
          Discover Award-Winning Independent Films on Festiv - The Filmmaker's
          Virtual Marketplace
        </h1>
        <p>
          Festiv's 24/7 global film marketplace showcases amazing award-winning
          independent feature films, shorts and episodic content from the
          brightest filmmakers and creators from around the world chosen by our
          team of film distribution and marketing executives, and award-winning
          producers, writers, and actors, for you to enjoy! Each year we host
          interactive online panel discussions featuring filmmakers,
          screenwriters, actors, and business leaders from around the world
          sharing their insights on various industry topics and we will host an
          annual event where our top films are shown on the big screen for live
          audiences. Get ready to be discovered, Submit Your Film Today!
        </p>
      </article>

      <div className="div-block-10">
        <h1 className="featured-films-title-homepage">NEW ARRIVALS</h1>
      </div>
      <section className="section-7">
        <div className="code-for-film-grid w-embed">
          <style>
            {`
  .movie-description:hover {
    opacity:1;
  }
      `}
          </style>
        </div>
        <section className="film-grid-section">
          <div className="film-grid-container">
            <div id="films-grid" className="w-layout-grid film-grid">
              {films &&
                films.length > 0 &&
                films.map((film) => (
                  <LandingPageFilmEntry
                    key={film.id}
                    id={`${film.id}`}
                    title={film.title}
                    year={film.year}
                    synopsis={film.synopsis}
                    runtime={film.runtime}
                    language={film.language}
                    country={film.country}
                    poster={film.poster}
                  />
                ))}
            </div>
          </div>
          <div className="html-embed-5 w-embed w-script"></div>
        </section>
      </section>
      <div class="spacer-div"></div>
      <div id="callout-logos" class="div-block-10 padding-bottom-0">
        <h2 class="header">CONNECT WITH PEOPLE FROM</h2>
        <ul>
          <li>
            <img
              src="/images/logos/hulu.png"
              class="callout-logo"
              alt="hulu logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/netflix.png"
              class="callout-logo"
              alt="netflix logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/walt-disney.png"
              class="callout-logo"
              alt="disney logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/amazon-studios.png"
              class="callout-logo"
              alt="amazon studio logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/showtime.png"
              class="callout-logo"
              alt="showtime logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/usc.png"
              class="callout-logo"
              alt="usc logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/pga.jpg"
              class="callout-logo"
              alt="pga logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/nyu.png"
              class="callout-logo"
              alt="nyu logo"
            />
          </li>
          <br />
          <li>
            <img
              src="/images/logos/caa.png"
              class="callout-logo"
              alt="caa logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/depaul.png"
              class="callout-logo"
              alt="depaul logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/AXN_Asia_Logo.png"
              class="callout-logo"
              alt="AXN Asia logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/Exibidor_Logo_-_White.png"
              class="callout-logo"
              alt="Exibidor logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/ExpoCine_Logo.png"
              class="callout-logo"
              alt="ExpoCine logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/KC_Global_Media_Logo.png"
              class="callout-logo"
              alt="KC Global Media logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/Mass_Media_Films_Logo.png"
              class="callout-logo"
              alt="Mass Media Films logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/Ultracine_Logo.png"
              class="callout-logo"
              alt="Ultracine logo"
            />
          </li>
          <li>
            <img
              src="/images/logos/and_more_for_landing_page.png"
              class="callout-logo"
              alt="and more button"
            />
          </li>
        </ul>
      </div>

      <div id="callout-logos" class="div-block-10 padding-top-0">
        <h2 class="header margin-bottom-25px">SELECTION COMMITTEE</h2>
        <ul class="text-decoration-none">
          <li>
            <a href="/about-us.html#samuel-real">
              <img
                src="/images/samuel-real-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Samuel Real"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Samuel Real</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Distribution Executive)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#allison-gibson">
              <img
                src="/images/allison-gibson-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Allison M. Gibson"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Allison M. Gibson</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Writer, Producer)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#larry-bates">
              <img
                src="/images/larry-bates-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Larry Bates"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Larry Bates</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Actor, Producer)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#rachel-lewis">
              <img
                src="/images/rachel-lewis-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Rachel Lewis"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Rachel Lewis</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Writer, Producer)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#brian-kimmet">
              <img
                src="/images/brian-kimmet-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Brian Kimmet"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Brian Kimmet</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Actor)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#danielle-iman">
              <img
                src="/images/danielle-iman.png"
                class="callout-logo margin-bottom-10"
                alt="Danielle Iman"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Danielle Iman</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Writer, Producer)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#armand-vasquez">
              <img
                src="/images/armand-vasquez-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Armand Vasquez"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Armand Vasquez</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Actor)</p>
              </span>
            </a>
          </li>
          <li>
            <a href="/about-us.html#kobie-jackson">
              <img
                src="/images/kobie-jackson-photo-p-130x130q80.png"
                class="callout-logo margin-bottom-10"
                alt="Kobie Jackson"
              />
              <span class="link-block selection-committee-landing-name">
                <h6>
                  <strong>Kobie Jackson</strong>
                </h6>
              </span>
              <span class="link-block selection-committee-landing-title">
                <p>(Marketing Executive)</p>
              </span>
            </a>
          </li>
        </ul>
      </div>

      <Footer />
    </div>
  );
}

export default LandingPage;
